<template>
    <b-col lg="6" md="12" sm="12" class="my-1">
        <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">
                            {{ title }}
                        </h5>
                        <span class="h2 font-weight-bold mb-0">{{
                            count
                        }}</span>
                    </div>
                    <div class="col-auto">
                        <div
                            class="icon icon-shape bg-gradient-red text-white rounded-circle shadow"
                        >
                            <i :class="'fi flaticon-' + icon"></i>
                        </div>
                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2"
                        ><b-icon-arrow-up></b-icon-arrow-up> {{ growth }}%</span
                    >
                    <span class="text-nowrap">Since last month</span>
                </p>
            </div>
        </div>
    </b-col>
</template>

<script>
export default {
    props: ["title", "count", "growth", "icon"]
};
</script>

<style scoped>
.card {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}
.icon-shape {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.icon-shape i {
    font-size: 1.25rem;
}
.icon {
    width: 3rem;
    height: 3rem;
}
p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
}
.bg-gradient-red {
    background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important;
}
</style>
