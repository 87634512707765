<template>
    <div id="world-map" style="width: 100%; height: 400px;"></div>
</template>
<script>
import $ from "jquery";
require("jvectormap-next")($);
require("./jquery-jvectormap-world-mill");

let gdpData = {
    FI: 52,
    NO: 110,
    SE: 158
};
export default {
    mounted() {
        $(this.$el).vectorMap({ 
            map: "world_mill",
            series: {
                regions: [
                    {
                        values: gdpData,
                        scale: ["#FA8AA0", "#D8002A"],
                        normalizeFunction: "polynomial"
                    }
                ]
            },
            onRegionTipShow: function(e, el, code) {
                let count = gdpData[code] || 0;
                el.html(el.html() + " (Locks - " + count + ")");
            }
        });
        false;
    }
};
</script>
<style>
@import "../../../node_modules/jvectormap-next/jquery-jvectormap.css";
</style>
