<template>
    <b-container>
        <b-row>
            <b-col cols="12">
            <b-navbar toggleable="lg" type="dark" variant="zssecondary">
                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-form-datepicker v-model="value" :min="min" :max="max" locale="en"></b-form-datepicker>
                    </b-nav-form>
                </b-navbar-nav>
            </b-navbar>
            </b-col>
        </b-row>
        <b-row align-v="center" align-self="center">
            <z-stats-card
                v-for="(item, index) in stats"
                v-bind:key="index"
                :title="item.title"
                :count="item.count"
                :growth="item.growth"
                :icon="item.icon"
            />
        </b-row>
        <b-row align-v="center" align-self="center">
            <b-col lg="6" md="12">
                <b-card title="System Downloaded">
                    <horizontal-bar-chart
                        :chartData="chartdata"
                        :options="options"
                    >
                    </horizontal-bar-chart>
                </b-card>
            </b-col>
            <b-col lg="6" md="12">
                <b-card title="Device Providers">
                    <b-card-body>
                        <horizontal-bar-chart
                            :chartData="chartdata2"
                            :options="options"
                        >
                        </horizontal-bar-chart>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row align-v="center" align-self="center">
            <b-col cols="12">
                <z-world-map />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ZStatsCard from "@/components/zdashboard/ZStatsCard";
import ZWorldMap from "@/components/zdashboard/ZWorldMap";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart";

export default {
    data() {
        return {
            stats: [
                {
                    title: "Active users",
                    count: 670,
                    growth: 5.5,
                    icon: "user"
                },
                {
                    title: "Active doors",
                    count: 1090,
                    growth: 32.2,
                    icon: "door"
                },
                {
                    title: "Active keys",
                    count: 858,
                    growth: 22.5,
                    icon: "key"
                },
                {
                    title: "Connected doors",
                    count: 345,
                    growth: 17.6,
                    icon: "door"
                },
                {
                    title: "Shared keys",
                    count: 670,
                    growth: 41.2,
                    icon: "key"
                },
                {
                    title: "Deliveries",
                    count: 10256,
                    growth: 82.4,
                    icon: "business-1"
                }
            ],
            chartdata: {
                labels: ["Android", "iOS"],
                datasets: [
                    {
                        maxBarThickness: 25,
                        backgroundColor: "#f87979",
                        data: [200000, 500000]
                    }
                ]
            },
            chartdata2: {
                labels: [
                    "DANALOCK",
                    "ROLLOCK",
                    "SIMONS VOSS",
                    "POPULIFE",
                    "CODE"
                ],
                datasets: [
                    {
                        maxBarThickness: 25,
                        backgroundColor: "#f87979",
                        data: [4910, 2500, 682, 355, 106]
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function(value, index, values) {
                                    if (value >= 1000) {
                                        var units = ["k", "M", "B", "T"];

                                        var order = Math.floor(
                                            Math.log(value) / Math.log(1000)
                                        );

                                        var unitname = units[order - 1];
                                        var num = Math.floor(
                                            value / 1000 ** order
                                        );
                                        return num + unitname;
                                    }
                                    return value;
                                }
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: true
            }
        };
    },
    components: {
        ZStatsCard,
        HorizontalBarChart,
        ZWorldMap
    }
};
</script>

<style scoped>
.bar-chart-wrapper {
    height: 310px;
}
</style>
